.crop-control-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .crop-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 50px;
    border: 1px solid #000;
    border-radius: 5px;
    margin: 10px 0;
    padding: 10px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    // border: 1px solid #000;
  }
  .btn-container {
    cursor: pointer;
    background-color: #a60071;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
  }
  #rotate-input,
  #scale-input {
    width: 60px;
  }
}

.style-slider {
  .MuiSlider-root
    .MuiSlider-colorPrimary
    .MuiSlider-sizeMedium
    css-1pdbvto-MuiSlider-root {
    padding: 5px 0 !important;
  }
}
