.step-one-cms {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .image-input {
    height: 40vh;

    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
    .image-input-img-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
