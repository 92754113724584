
.img_container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;    
    border-radius: 10px;
    .img{
        width: 100%;
        height: 100%;
        margin:0 auto;
        border-radius:10x !important;
    }
}




// @media screen  and (max-width:1050px){
//     .img{
//         width: 100%;
//         height:120px;
//         margin:0 auto;
//         border-radius:50%;
//     }
//     .img_container{
//         width: 17%;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//     }
// }

// @media screen  and (max-width:930px){
//     .img{
//         width: 100%;
//         height:120px;
//         margin:0 auto;
//         border-radius:50%;
//     }
//     .img_container{
//         width: 25%;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//     }
// }

// @media screen  and (max-width:600px){
//     .img{
//         width: 100%;
//         height:120px;
//         margin:0 auto;
//         border-radius:50%;
//     }
//     .img_container{
//         width: 30%;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//     }
// }

// @media screen  and (max-width:500px){
//     .img{
//         width: 100%;
//         height:100%;
//         margin:0 auto;
//         border-radius:50%;
//     }
//     .img_container{
//         width: 150px;
//         height:180px;
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         justify-content: center;
//     }
    
// }


.uploadFileBtn{    
    width: 100%;
    height: 300px;
    .upload{
        display: flex;
        align-items: center;
        justify-content:center;
        border-radius:10px;    
        width:100%;
        height: 100%;
        margin:0 auto;
        .preview{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            border: 1px solid #000;
            width: 100%;
            height: 100%;
            span{
                margin: 10px;
            }
        }
        
    }
    
}






@media screen  and (max-width:400px){
    
    .img_container{
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .img{
            width: 100%;
            height:100%;
            margin:0 auto;
            border-radius:50%;
        }
    }
   
}
