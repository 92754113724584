@import "../../styles/variables.scss";
.onboarding {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .top-bar {
    position: absolute;
    top: 0;
    left: 20px;
    width: 0%;
    .logo {
      padding: 5px 20px;
      width: 70px;
      img {
        width: 100%;
      }
    }
  }
  .tab-container {
    width: 40%;
    padding: 0.5% 1%;
    border: solid 1px #dedede59;
    // border: solid 1px #000;
    border-radius: 10px;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        border-left: #dedede59 solid 1px;
        // border-left: #000 solid 1px;
        padding: 1% 2%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      & > :first-child {
        border-left: 0 !important ;
      }
    }
  }

  .content-container {
    padding: 1%;

    margin-top: 30px;
    h2,
    p {
      margin: 0;
    }
    .header {
      font-size: 2rem;
      color: #000;
      font-weight: 700;
    }

    .content {
      font-size: 14px;
      margin-top: 10px;
    }
  }
  .form-container {
    padding: 1%;
    width: 600px;
    margin-top: 20px;
    .form-type {
      .preview-image {
        margin-top: 25px;
        padding: 10px;
        border: solid 1px #dedede59;
        border-radius: 10px;
        img {
          width: 450px;
          height: 300px;
        }
      }
    }
  }

  .control-section {
    padding: 1%;
    display: flex;
    flex-direction: row-reverse;
    // width: 40%;
    width: 600px;

    margin-top: 25px;
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      // padding: 0.5rem;
      .back-control {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 60px;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        border-radius: 10px;
        padding: 6% 8%;
        width: fit-content;
        &:hover {
          background-color: rgba(239, 239, 239, 0.455);
        }
      }

      .button-control {
        cursor: pointer;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        background-color: #a60071;
        border-radius: 10px;
        padding: 6% 8%;
        text-align: center;
        // min-width: fit-content;
        width: 100px !important;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
  }
}

.payment-dialog-container {
  width: 500px;
  // height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;

  .header {
    font-size: 1.5rem;
    font-weight: 700;
    width: 100%;
    text-align: left;
    margin: 20px 0;
    margin-top: 0px;
    padding: 10px 0;
    border-bottom: 2px solid rgb(222, 220, 220);
    img {
      width: 80px;
      margin-bottom: 2%;
    }
    .section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .label {
        font-weight: 500;
        font-size: 0.9rem;
      }
      .value {
        font-weight: 500;
        font-size: 0.9rem;
      }
      margin-bottom: 5px;
    }

    .net-pay {
      .label {
        font-weight: 700;
        font-size: 0.9rem;
      }
      .value {
        font-weight: 700;
        font-size: 0.9rem;
      }
    }
    .payment-margin {
      border-bottom: 2px solid #a60071;
      margin-top: 1rem;
      margin-bottom: 10px;
    }
  }
  .content {
    margin: 20px auto;
    font-size: 1rem;
  }

  .btn-container-payment {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    .button-control {
      cursor: pointer;
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      background-color: #a60071;
      border-radius: 10px;
      padding: 4% 6%;
      text-align: center;
      // min-width: fit-content;
      margin-left: 10px;
      width: 80% !important;
    }

    .button-control-close {
      cursor: pointer;
      color: #000;
      font-size: 1rem;
      font-weight: 600;
      background-color: #fff;
      border: 1px solid #a60071;
      border-radius: 10px;
      padding: 4% 6%;
      text-align: center;
      margin-right: 10px;
      // min-width: fit-content;
      width: 80% !important;
    }
    .button-control-pay {
      cursor: pointer;
      color: #fff;
      font-size: 0.8rem;
      font-weight: 500;
      background-color: #a60071;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      text-transform: uppercase;
      min-width: 64px;
      padding: 6px 8px;
      border-radius: 4px;
      text-align: center;
      min-width: 64px;
      // width: 80% !important;
    }

    .button-control-close-pay {
      cursor: pointer;
      color: #a60071;
      font-size: 0.8rem;
      font-weight: 500;
      background-color: #fff;
      // border: 1px solid #a60071;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      text-transform: uppercase;
      min-width: 64px;
      padding: 6px 8px;
      border-radius: 4px;
      text-align: center;
      margin-right: 10px;
      min-width: 64px;
    }
    .button-control-close-diable {
      color: #3f3e3e;
    }
  }
}

.redisblackz {
  background-color: red;
}
@media screen and (max-width: 1000px) {
  .onboarding {
    .control-section {
      .controls {
        width: 100%;
        .button-control {
          justify-content: center;
          align-items: center;
          .back-control {
            padding: 3% 4%;
          }
          .button-control {
            padding: 3% 4%;
          }
        }
      }
    }
  }
}
