.brand-select {
  display: flex;
  justify-content: center;
  margin-top: 25px;

  .label {
    display: inline-block;
    margin-right: 50px;
    font-size: 0.8rem;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
  }

  .field {
    display: inline-block;
    border: none;
    margin-left: auto;
    font-family: "Montserrat", sans-serif;
    width: 250px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    border: 1.5px solid rgb(223, 223, 223);
    height: 350px;

    &:focus {
      outline: none;
      border: 1.5px solid rgb(166, 0, 113);
    }
  }
}
