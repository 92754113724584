.create-container {
  background-color: #fff;
  .top-preview {
    position: relative;
    padding: 0 4%;
    right: 0;
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    height: 75px;
    box-shadow: 6px 6px 24px 6px hsl(300deg 6% 90% / 48%);
    margin-bottom: 5%;

    .options {
      display: flex;
      justify-content: center;
      align-items: center;

      .add-brand-btn {
        font-family: "Montserrat", sans-serif;
        display: flex;
        margin-left: auto;
        margin-right: 30px;

        .add-brand-content {
          background-color: #a60071;
          color: white;
          padding: 0px 30px;
          border-radius: 5px;

          .add-icon {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
          }

          .add-text {
            display: inline-block;
            vertical-align: middle;
            font-size: 0.7rem;
          }
        }
      }
      a {
      }
    }
  }

  .botton-create-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
}
