.paper {
  height: 70%;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 25px;
  border-radius: 10px;
  box-shadow: 0 0 10px 1px rgb(241, 241, 241);
  padding-left: 10px;
  padding-right: 10px;

  .user {
    width: 100%;
    height: 45%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .details {
      display: flex;
      justify-content: center;
      align-items: center;

      .avtar {
        height: 180px;
        width: 400px;
        object-fit: contain;
        border-radius: 10px;
        margin-right: 15px;
      }

      .info {
        margin-left: 15px;

        .info-item {
          margin-top: 15px;
          margin-bottom: 15px;

          .info-value {
            font-weight: 600;
          }
        }
      }
    }

    .options {
      width: 180px;

      .option-btn {
        width: 180px;
        font-family: "Montserrat", sans-serif;
        border: 1px solid rgb(166, 0, 113);
        color: rgb(166, 0, 113);
        font-size: 0.8rem;
        cursor: pointer;

        margin-bottom: 10px;
        margin-top: 10px;

        &:hover {
          background-color: rgb(166, 0, 113);
          color: white;
        }
      }

      .option-btn-red {
        width: 180px;
        font-family: "Montserrat", sans-serif;
        border: 1px solid red;
        color: red;
        font-size: 0.8rem;

        margin-bottom: 10px;
        margin-top: 10px;

        &:hover {
          background-color: red;
          color: white;
        }
      }
    }
  }

  .Updates {
    width: 90%;
    height: max-content;
    margin-top: 10px;
    margin-left: 50px;

    .title {
      font-weight: 600;
    }

    .updates-lists {
      margin-top: 15px;
      line-height: 1.5rem;
    }
  }
}

// .admin-profile {

.coupon-control {
  margin-top: 20px;
  padding: 0 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    display: block;
    margin: 10px 0 0px 0;
    // padding: 10px 14px;
    font-size: 2em;
    // box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    //   rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 1px solid rgb(221, 217, 217);
    padding: 8px;
    outline: 0;
    border-radius: 10px;
    background: white;

    &:focus {
      border: 1px solid #b51783;
    }
  }
  // }
  .payment-input {
    label {
      font-size: 1rem;
      font-weight: 500;
      color: #b51783;
    }
  }

  .button-control-pay {
    cursor: pointer;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 500;
    background-color: #a60071;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 12px 8px;
    border-radius: 4px;
    text-align: center;
    min-width: 64px;

    // width: 80% !important;
  }
  .coupon-success {
    color: rgb(6, 155, 38);
    font-size: .9rem;
    
  }
  .error-payment {
    color: red;
  }
}
