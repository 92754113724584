.onboard-tab{
    display: flex;
    justify-content: space-evenly;
    align-items: center;  
    // width: 80px; 
    // border-left: #dedede59 solid 1px;
    // border-left: #000 solid 1px;
    
    .tab-icon{
        // margin: 2%;        
        color: rgb(158, 150, 195);
        border-radius: 100%;
        width: 50px;
        height: 50px;        
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
            background-color: #dedede59;
            cursor: pointer;
        }
        svg{
            height:1.5em !important;
            width:1.5em !important;
        }
    } 


    .tab-icon-active{
        // margin: 2%;        
        background-color: #b51783;
        color: #ffff;
        border-radius: 100%;
        width: 40px;
        height: 40px;        
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            height:1em !important;
            width:1em !important;
        }
    } 
    // transition: all linear .5s;
    .tab-info{
        width: 0;
        display:none;
        height: 0;
        
        // overflow: hidden;
        .step-count{
            font-size: 12px;
            font-weight: 600;
            color: #c4459c;
        }
    
    
        .step-count-active{
            font-size: 12px;
            font-weight: 600;
            color: #aa50ff;
        }
    
        .tab-name{
            font-size: 1rem;
            font-weight: 700;
        }
    
    
        .tab-name-active{
            font-size: 14px;
            font-weight: 700;
        }
    }
    
    .tab-info-active{
        width: auto;
        display:block !important;
        height: auto;
        
    }
    
    p{
        margin: 0;
        padding: 0;
    }
}

.onboard-tab-active{
    // width:fit-content;
    // width: auto;
    min-width: 170px; 
    max-width: auto; 
    white-space: nowrap;
}