.step-two-cms {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .options-block {
    position: relative;
    width: 80%;

    .add-brand-btn {
      font-family: "Montserrat", sans-serif;
      display: inline-block;
      margin-left: auto;
      margin-right: 10px;

      .add-brand-content {
        background-color: #a60071;
        color: white;
        padding: 0px 20px;
        border-radius: 5px;

        .add-icon {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }

        .add-text {
          display: inline-block;
          vertical-align: middle;
          font-size: 0.7rem;
        }
      }
    }
  }
}
