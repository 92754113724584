.form-container {
  display: flex;
  flex-direction: column;
  .header {
    width: 100%;
    height: 120px;
    background-color: #b51783;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      width: 180px;

      img {
        width: 100%;
      }
    }
  }

  .form-box {
    padding: 30px;
  }
}
