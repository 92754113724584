.image-input {
  min-height: 28vh;
  //   margin-bottom: 25px;
  //   border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  //   margin-bottom: 2%;
  position: relative;
  .image-container {
    width: 100%;
    // max-height: 60vh;
    img {
      width: 100%;
      height: 100%;
      // max-height: 60vh;
    }
  }
}
