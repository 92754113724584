.forgot-pwd-change-component {
    .title-forgot-pwd-change {
      font-size: 1.6rem;
      font-weight: bold;
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
  
    .title-forgot-pwd-change-tag {
      color: grey;
      margin-top: -20px;
      font-size: 0.8rem;
      margin-bottom: 50px;
    }
  
    .email-change-div {
      display: flex;
      align-items: center;
      justify-content: center;
  
      .text {
        display: inline-block;
        margin-right: 50px;
        font-size: 0.8rem;
        font-weight: 700;
  
        .email {
          font-weight: 500;
          padding-left: 10px;
        }
      }
  
      .btn {
        display: inline-block;
        border: none;
        margin-left: auto;
        font-size: 0.8rem;
        font-weight: 700;
        color: rgb(166, 0, 113);
        cursor: pointer;
        text-decoration: underline;
  
        &:hover {
          transform: translateY(-2px);
        }
      }
    }
  
    .continue-btn {
      background-color: rgb(166, 0, 113);
      border: 2px solid rgb(166, 0, 113);
      color: white;
      font-family: "Montserrat", sans-serif;
      padding: 8px 15px;
      font-size: 0.7rem;
      float: right;
      margin-top: 35px;
  
      &:hover {
        background-color: rgb(166, 0, 113);
        transform: translateY(-2px);
      }
  
      .loading {
        .spin {
          display: inline-block;
          vertical-align: middle;
        }
  
        .spacer {
          display: inline-block;
          margin-left: 20px;
          vertical-align: middle;
        }
      }
    }
  
    .btn-accept {
      border: 1px solid;
      border-color: rgb(166, 0, 113);
      color: rgb(166, 0, 113);
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 10px;
      font-family: "Montserrat", sans-serif;
      font-size: 0.8rem;
    }
  
    @media (max-width: 900px) {
      padding-left: 25px;
      padding-right: 25px;
  
      .title-forgot-pwd-change {
        text-align: center;
      }
  
      .title-forgot-pwd-change-tag {
        text-align: center;
      }
  
      .continue-btn {
        margin-top: 50px;
      }
    }
  }
  