.daisy-con-container {
  display: flex;
  flex-direction: column;
  .accordion-title {
    font-size: 1rem;
    font-weight: 500;
  }
  .number-and-option {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}
