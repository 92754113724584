.br-weights {
  height: 100vh;
  background-color: rgb(249, 249, 249);

  .btn-control-link {
    display: inline-block;
    font-family: Montserrat, sans-serif;
    margin-left: auto;
    margin-right: 30px;
  }
  .add-brand-btn {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    margin-left: auto;
    margin-right: 10px;

    .add-brand-content {
      background-color: #a60071;
      color: white;
      padding: 0px 30px;
      border-radius: 5px;

      .add-icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
      }

      .add-text {
        display: inline-block;
        vertical-align: middle;
        font-size: 0.7rem;
      }
    }
  }
  .head {
    display: flex;
    justify-content: flex-start;

    .head-txt {
      padding: 30px 50px;
      padding-right: 0px;
      font-size: 1.9rem;
      font-weight: 500;
      display: inline-block;
    }

    .head-refresh {
      padding-top: 45px;
      color: rgb(166, 0, 113);
      padding-left: 5px;
      font-size: 17px;
      cursor: pointer;
    }
  }

  .secondary-div {
    display: flex;
    justify-content: flex-start;

    .secondary-content {
      display: inline-block;
      vertical-align: middle;
      margin-left: 40px;
      // cursor: pointer;

      .selected-text {
        font-weight: 600;
      }
    }

    .input-container {
      margin-left: auto;
      margin-right: 30px;
      margin-bottom: 10px;
      width: 300px;
      border-radius: 100px;
      background-color: white;
      display: flex;
      align-items: center;
      box-shadow: 0 0 10px 1px rgb(241, 241, 241);

      .icon {
        height: 20px;
        color: #8c8c8c;
        min-width: 50px;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
      }

      .input-field {
        font-family: 'Montserrat', sans-serif;
        width: 100%;
        padding: 10px;
        height: 20px;
        border: none;
        border-bottom-right-radius: 100px;
        border-top-right-radius: 100px;
        padding-left: 0;
        &:active {
          border: none;
          outline: none;
        }

        &:focus {
          border: none;
          outline: none;
        }
      }
    }

    .search-field {
      margin-left: auto;
      margin-right: 30px;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 300px;
      border-radius: 100px;
    }
  }

  .table-paper {
    height: 70%;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 25px;
    border-radius: 10px;
    box-shadow: 0 0 10px 1px rgb(241, 241, 241);
    padding-left: 10px;

    .actions-div {
      width: 100px;
      display: flex;
      justify-content: center;
    }

    .tracking-switch-div {
      width: 100px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.multi-menu-table {
  display: flex;
  width: 100%;
  .menu-col-container {
    background-color: #fff;

    border-bottom: 1px solid #dadce0;

    display: inline-block;
    position: relative;
    width: 300px;
    transition: width ease-in 0.3s;
    .menu-header {
      border-bottom: 1px solid #dadce0;
      border-top: 1px solid #dadce0;

      font-weight: 600;
      font-size: 1.25rem;
      color: #5f6368;
      font-weight: 500;
      letter-spacing: 0.3px;
      letter-spacing: 0.3px;
      padding: 12px 12px 12px 24px;
      display: flex;
      justify-content: space-between;
      .search-header {
        padding: 3px 8px;
        border-radius: 5px;
        border: 1px solid #dadce0;
        outline: none;
        color: #5f6368;
      }
    }
    &:hover {
      width: 450px;
    }
  }
  .menu-body {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 80vh;
    .menu-row {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 6px 6px 24px;
      .content-container {
        .brand {
          text-wrap: nowrap;
          color: #232527;
          font-size: 1.2rem;
          font-weight: 500;
          margin: 5px;
        }
        .id {
          margin: 5px;
          color: #5f6368;
          font-size: 1rem;
          font-weight: 500;
        }
      }

      &:hover {
        background-color: #f1f3f4;
      }
    }
    .selected {
      background-color: #f1f3f4;
    }
  }
}

.ga4-or-ua {
  background-color: #a60071;
  border-radius: 5px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  color: #ffff;
}
