.modal {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba($color: #000000, $alpha: 0.4);
    transition: 0.3s ease-in;
  
    font-size: 0.8rem;
  
    &.active {
      opacity: 1;
      visibility: visible;
    }
  
    &__content {
      width: 700px;
      height: 400px;
      margin: 5% auto;
      background-color: #fff;
      border-radius: 5px;
      position: relative;
      transition: inherit;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-100px);
  overflow: scroll;
      padding: 30px;
      font-family: "Montserrat", sans-serif;
    }
  
    &.active &__content {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
  
  .modal__header {
    font-size: 1rem;
    font-weight: bold;
  }
  
  @media (max-width: 900px) {
    .modal {
      &__content {
        width: 300px;
        margin: 50% auto;
      }
    }
  }
  