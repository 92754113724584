.editor-container {
  width: 80%;
  position: relative;
  margin-bottom: 5%;
  .editors {
  }
  .delete-button {
    position: absolute;
    right: 0;
    color: #c20d0d;
  }
}
