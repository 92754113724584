.users {
  height: 100vh;
  background-color: rgb(249, 249, 249);

  .right-drawer {
    .title {
      font-family: 'Montserrat', sans-serif;
    }
  }

  .head {
    display: flex;
    justify-content: flex-start;

    .head-txt {
      padding: 30px 50px;
      padding-right: 0px;
      font-size: 1.9rem;
      font-weight: 500;
      display: inline-block;
    }

    .head-refresh {
      padding-top: 45px;
      color: rgb(166, 0, 113);
      padding-left: 5px;
      font-size: 17px;
      cursor: pointer;
    }

    .add-user-btn {
      font-family: 'Montserrat', sans-serif;
      display: inline-block;
      margin-left: auto;
      margin-right: 30px;

      .add-user-content {
        background-color: rgb(166, 0, 113);
        color: white;
        padding: 0px 30px;
        border-radius: 5px;

        .add-icon {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }

        .add-text {
          display: inline-block;
          vertical-align: middle;
          font-size: 0.7rem;
        }
      }
    }
  }

  .toggle-div {
    display: flex;
    justify-content: flex-start;

    .toggle-content {
      display: inline-block;
      vertical-align: middle;
      margin-left: 40px;
      cursor: pointer;

      .selected-toggle-text {
        font-weight: 600;
      }

      .selected-toggle-bar {
        height: 3px;
        background-color: rgb(166, 0, 113);
        border-radius: 50px;
      }
    }

    .input-container {
      margin-left: auto;
      margin-right: 30px;
      margin-bottom: 10px;
      width: 300px;
      border-radius: 100px;
      background-color: white;
      display: flex;
      align-items: center;
      box-shadow: 0 0 10px 1px rgb(241, 241, 241);

      .icon {
        height: 20px;
        color: #8c8c8c;
        min-width: 50px;
        text-align: center;
        vertical-align: middle;

        cursor: pointer;
      }

      .input-field {
        font-family: 'Montserrat', sans-serif;
        width: 100%;
        padding: 10px;
        height: 20px;
        border: none;
        border-bottom-right-radius: 100px;
        border-top-right-radius: 100px;
        padding-left: 0;
        &:active {
          border: none;
          outline: none;
        }

        &:focus {
          border: none;
          outline: none;
        }
      }
    }

    .search-field {
      margin-left: auto;
      margin-right: 30px;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 300px;
      border-radius: 100px;
    }
  }

  .table-paper {
    height: 70%;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 25px;
    border-radius: 10px;
    box-shadow: 0 0 10px 1px rgb(241, 241, 241);
    padding-left: 10px;

    .actions-div-user {
      // width: 100px;
      display: flex;
      justify-content: center;
    }

    .tracking-switch-div {
      width: 100px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .foot {
    text-align: center;
    font-size: smaller;
    color: rgb(38, 38, 38);
    height: 40px;
    position: absolute;
    bottom: 0;
    left: 50%;
  }
}
