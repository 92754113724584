.crmUserAnalytics-component {
  height: 100vh;
  background-color: white;
  overflow: hidden;

  .report {
    width: 100%;
    height: 104%;
    border: none;
  }
}
