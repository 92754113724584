.date-picker-custom {
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 9px 14px !important;
    padding-right: 0;
    font-size: 0.98rem;
  }

  input {
    padding: 9px 14px !important;
    padding-right: 0;
    font-size: 0.98rem;
  }

  .css-hkcol8-MuiFormLabel-root-MuiInputLabel-root {
    top: -8px !important;
  }

  label {
    top: -8px !important;
  }
}
