@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.App {
  font-family: "Montserrat", sans-serif;
}
* {
  font-family: "Montserrat", sans-serif;
}
.toast-success-container {
  background-color: #b51783 !important;
}

.toast-wrong-container {
  background-color: #da383b !important;
}

.toast-style-ltp {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
