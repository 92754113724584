.payment-ltp-container {
  .payment-grid {
    display: grid;
    grid-template-columns: 70% 30%;
  }
  input,
  .StripeElement {
    display: block;
    margin: 10px 0 0px 0;
    // padding: 10px 14px;
    font-size: 2em;
    // box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    //   rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 1px solid rgb(221, 217, 217);
    padding: 15px;
    outline: 0;
    border-radius: 10px;
    background: white;

    &:focus {
      border: 1px solid #b51783;
    }
  }
  .payment-input {
    label {
      font-size: 1rem;
      font-weight: 500;
      color: #b51783;
    }
  }

  .coupon-container {
    display: flex;
    width: 100%;
  }
  #card-owner-name-lpt {
    display: block;
    margin: 10px 0 10px 0;
    padding: 0px 14px;
    font-size: 0.85em;
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 15px;
    outline: 0;
    border-radius: 4px;
    background: white;
    width: 90%;
    font-weight: 500;

    &::placeholder {
      color: "#00000099";
      font-size: 0.85em;
    }
  }

  #coupon-lpt {
    display: block;

    font-size: 0.85em;
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 3px;
    outline: 0;
    border-radius: 4px;
    background: white;
    width: 100%;
    font-weight: 500;

    &::placeholder {
      color: "#00000099";
      font-size: 0.85em;
    }
    .input-coupon {
      display: block;
      margin: 0px;
      font-size: 1em;
      font-weight: 500;
      border: 0;
      padding: 15;
      outline: 0;
      width: 80%;
      /* border-radius: 10px; */
      background: white;
    }
  }
  .cardNumber-ltp {
    width: 90%;
    border-radius: 4px;
  }
  .cardCvc-ltp {
    width: 79%;
    border-radius: 4px;
  }
  .cardExpiry-ltp {
    width: 90%;
    border-radius: 4px;
  }
  .error-payment {
    color: red;
    font-size: 0.8em;
    font-weight: 500;
    margin: 10px 0;
  }

  .success-payment {
    color: rgb(6, 155, 38);
    font-size: 0.8em;
    font-weight: 500;
    margin: 10px 0;
  }
  .StripeElement--focus {
    border: 1px solid #b51783;
  }
  .StripeElement--empty,
  .StripeElement--complete {
    border-color: rgba(0, 0, 0, 0.23);
    color: #868686de;
  }

  .StripeElement--invalid {
    border: 1px red solid;
  }
  .button-control-pay {
    cursor: pointer;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 500;
    background-color: #a60071;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 12px 8px;
    border-radius: 4px;
    text-align: center;
    min-width: 64px;
    // width: 80% !important;
  }
  .coupon-success {
    color: rgb(6, 155, 38);
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-right: 10px;
    }
  }
}
