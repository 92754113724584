.onboarding-invite {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .top-bar {
    width: 100%;
    .logo {
      padding: 0 5px;
      width: 50px;
      img {
        width: 100%;
      }
    }
  }
  .tab-container {
    width: 40%;
    padding: 0.5% 1%;
    border: solid 1px #dedede59;
    // border: solid 1px #000;
    border-radius: 10px;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        border-left: #dedede59 solid 1px;
        // border-left: #000 solid 1px;
        padding: 1% 2%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      & > :first-child {
        border-left: 0 !important ;
      }
    }
  }

  .content-container {
    padding: 1%;
    width: 40%;
    margin-top: 30px;
    h2,
    p {
      margin: 0;
    }
    .header {
      font-size: 2rem;
      color: #000;
      font-weight: 700;
    }

    .content {
      font-size: 14px;
      margin-top: 10px;
    }
  }
  .form-container {
    padding: 1%;
    width: 40%;
    margin-top: 20px;
    .form-type {
      .preview-image {
        margin-top: 25px;
        padding: 10px;
        border: solid 1px #dedede59;
        border-radius: 10px;
        img {
          width: 450px;
          height: 300px;
        }
      }
    }
    .form-3 {
      .net-list {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        .card {
          cursor: pointer;
          margin: 0 auto;
          border: 1px solid #e7ecf1 !important;
          border-radius: 5px;
          padding: 10px;
          margin: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .selection {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
          .title {
            text-align: center;
            margin: 0 auto 20px;
            color: #2f353b;
            font-size: 18px;
          }
          .image-holder {
            height: 70px;
            margin: 0 auto;
            max-width: 200px;
            min-width: 80px;
            text-align: center;
            width: 60%;

            img {
              max-width: 100%;
              max-height: 60px;
              width: auto;
              height: auto;
              position: relative;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          &:hover {
            border: 1px solid #b51783 !important;
          }
        }
      }
    }
  }

  .control-section {
    padding: 1%;
    display: flex;
    flex-direction: row-reverse;
    width: 40%;
    margin-top: 25px;
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 35%;
      .back-control {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 60px;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        border-radius: 10px;
        padding: 6% 8%;
        width: fit-content;
        &:hover {
          background-color: rgba(239, 239, 239, 0.455);
        }
      }

      .button-control {
        cursor: pointer;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        background-color: #b51783;
        border-radius: 10px;
        padding: 6% 8%;
        width: fit-content;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .onboarding-invite {
    .content-container {
      // padding: 1%;
      width: 40%;
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .onboarding-invite {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .top-bar {
      width: 100%;
      .logo {
        padding: 0 5px;
        width: 50px;
        img {
          width: 100%;
        }
      }
    }
    .tab-container {
      width: 70%;
      padding: 0.5% 1%;
      border: solid 1px #dedede59;
      // border: solid 1px #000;
      border-radius: 10px;
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          border-left: #dedede59 solid 1px;
          // border-left: #000 solid 1px;
          padding: 1% 2%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
        & > :first-child {
          border-left: 0 !important ;
        }
      }
    }

    .content-container {
      padding: 1%;
      width: 70%;
      margin-top: 30px;
      h2,
      p {
        margin: 0;
      }
      .header {
        font-size: 2rem;
        color: #000;
        font-weight: 700;
      }

      .content {
        font-size: 14px;
        margin-top: 10px;
      }
    }
    .form-container {
      padding: 1%;
      width: 70%;
      margin-top: 20px;
      .form-type {
        .preview-image {
          margin-top: 25px;
          padding: 10px;
          border: solid 1px #dedede59;
          border-radius: 10px;
          img {
            width: 450px;
            height: 300px;
          }
        }
      }
      .form-3 {
        .net-list {
          display: grid;
          grid-template-columns: 33% 33% 33%;
          .card {
            cursor: pointer;
            margin: 0 auto;
            border: 1px solid #e7ecf1 !important;
            border-radius: 5px;
            padding: 10px;
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .selection {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
            .title {
              text-align: center;
              margin: 0 auto 20px;
              color: #2f353b;
              font-size: 18px;
            }
            .image-holder {
              height: 70px;
              margin: 0 auto;
              max-width: 200px;
              min-width: 80px;
              text-align: center;
              width: 60%;

              img {
                max-width: 100%;
                max-height: 60px;
                width: auto;
                height: auto;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            &:hover {
              border: 1px solid #b51783 !important;
            }
          }
        }
      }
    }

    .control-section {
      padding: 1%;
      display: flex;
      flex-direction: row-reverse;
      width: 40%;
      margin-top: 25px;
      .controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 35%;
        .back-control {
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-width: 60px;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          border-radius: 10px;
          padding: 6% 8%;
          width: fit-content;
          &:hover {
            background-color: rgba(239, 239, 239, 0.455);
          }
        }

        .button-control {
          cursor: pointer;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          background-color: #b51783;
          border-radius: 10px;
          padding: 6% 8%;
          width: fit-content;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .onboarding-invite {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .top-bar {
      width: 100%;
      .logo {
        padding: 0 5px;
        width: 50px;
        img {
          width: 100%;
        }
      }
    }
    .tab-container {
      width: 70%;
      padding: 0.5% 1%;
      border: solid 1px #dedede59;
      // border: solid 1px #000;
      border-radius: 10px;
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          border-left: #dedede59 solid 1px;
          // border-left: #000 solid 1px;
          padding: 1% 2%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
        & > :first-child {
          border-left: 0 !important ;
        }
      }
    }

    .content-container {
      padding: 1%;
      width: 70%;
      margin-top: 30px;
      h2,
      p {
        margin: 0;
      }
      .header {
        font-size: 2rem;
        color: #000;
        font-weight: 700;
      }

      .content {
        font-size: 14px;
        margin-top: 10px;
      }
    }
    .form-container {
      padding: 1%;
      width: 70%;
      margin-top: 20px;
      .form-type {
        .preview-image {
          margin-top: 25px;
          padding: 10px;
          border: solid 1px #dedede59;
          border-radius: 10px;
          img {
            width: 450px;
            height: 300px;
          }
        }
      }
      .form-3 {
        .net-list {
          display: grid;
          grid-template-columns: 50% 50%;
          .card {
            cursor: pointer;
            margin: 0 auto;
            border: 1px solid #e7ecf1 !important;
            border-radius: 5px;
            padding: 10px;
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .selection {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
            .title {
              text-align: center;
              margin: 0 auto 20px;
              color: #2f353b;
              font-size: 18px;
            }
            .image-holder {
              height: 70px;
              margin: 0 auto;
              max-width: 200px;
              min-width: 80px;
              text-align: center;
              width: 60%;

              img {
                max-width: 100%;
                max-height: 60px;
                width: auto;
                height: auto;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            &:hover {
              border: 1px solid #b51783 !important;
            }
          }
        }
      }
    }

    .control-section {
      padding: 1%;
      display: flex;
      flex-direction: row-reverse;
      width: 40%;
      margin-top: 25px;
      .controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 35%;
        .back-control {
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-width: 60px;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          border-radius: 10px;
          padding: 6% 8%;
          width: fit-content;
          &:hover {
            background-color: rgba(239, 239, 239, 0.455);
          }
        }

        .button-control {
          cursor: pointer;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          background-color: #b51783;
          border-radius: 10px;
          padding: 6% 8%;
          width: fit-content;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .onboarding-invite {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .top-bar {
      width: 100%;
      .logo {
        padding: 0 5px;
        width: 50px;
        img {
          width: 100%;
        }
      }
    }
    .tab-container {
      width: 70%;
      padding: 0.5% 1%;
      border: solid 1px #dedede59;
      // border: solid 1px #000;
      border-radius: 10px;
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          border-left: #dedede59 solid 1px;
          // border-left: #000 solid 1px;
          padding: 1% 2%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
        & > :first-child {
          border-left: 0 !important ;
        }
      }
    }

    .content-container {
      padding: 1%;
      width: 70%;
      margin-top: 30px;
      h2,
      p {
        margin: 0;
      }
      .header {
        font-size: 2rem;
        color: #000;
        font-weight: 700;
      }

      .content {
        font-size: 14px;
        margin-top: 10px;
      }
    }
    .form-container {
      padding: 1%;
      width: 70%;
      margin-top: 20px;
      .form-type {
        .preview-image {
          margin-top: 25px;
          padding: 10px;
          border: solid 1px #dedede59;
          border-radius: 10px;
          img {
            width: 450px;
            height: 300px;
          }
        }
      }
      .form-3 {
        .net-list {
          display: grid;
          grid-template-columns: 100%;
          .card {
            cursor: pointer;
            margin: 0 auto;
            border: 1px solid #e7ecf1 !important;
            border-radius: 5px;
            padding: 10px;
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .selection {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
            .title {
              text-align: center;
              margin: 0 auto 20px;
              color: #2f353b;
              font-size: 18px;
            }
            .image-holder {
              height: 70px;
              margin: 0 auto;
              max-width: 200px;
              min-width: 80px;
              text-align: center;
              width: 60%;

              img {
                max-width: 100%;
                max-height: 60px;
                width: auto;
                height: auto;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            &:hover {
              border: 1px solid #b51783 !important;
            }
          }
        }
      }
    }

    .control-section {
      padding: 1%;
      display: flex;
      flex-direction: row-reverse;
      width: 40%;
      margin-top: 25px;
      .controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 35%;
        .back-control {
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-width: 60px;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          border-radius: 10px;
          padding: 6% 8%;
          width: fit-content;
          &:hover {
            background-color: rgba(239, 239, 239, 0.455);
          }
        }

        .button-control {
          cursor: pointer;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          background-color: #b51783;
          border-radius: 10px;
          padding: 6% 8%;
          width: fit-content;
        }
      }
    }
  }
}
