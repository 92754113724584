.auth {
  // height: Min(821px, 100vh);
  // width: Min(1440px, 100vw);
  font-family: "Montserrat", sans-serif;
  .web {
    height: 100vh;
    width: 100vw;
    margin: 0px;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: rgb(185, 193, 202);

    .bgnd-img-top,
    .bgnd-img-bottom {
      transform: translate3d(0, 0, 0);
      animation: moveSlideLeft 120s linear infinite;
      margin: 0;
      padding: 0;
    }

    .bgnd-img-middle {
      transform: translate3d(0, 0, 0);
      animation: moveSlideRight 120s linear infinite;
      margin: 0;
      padding: 0;
    }

    .carbon {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      opacity: 60%;
      background-color: #a80073;
    }

    .card {
      height: 80vh;
      width: 80vw;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

      .left-item {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(246, 246, 246);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      .right-item {
        // background-color: rgb(166, 0, 113);
        background-color: rgb(255, 255, 255);
        height: 80vh;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        .content {
          // .dash-image {
          //   height: 200px;
          //   border-radius: 5px;
          //   display: block;
          //   margin-left: auto;
          //   margin-right: auto;
          // }
          .svg-container {
            display: block;
            margin-left: auto;
            margin-right: auto;

            #card_1,
            #card_2 {
              animation: card 5s linear infinite;
            }

            #card_main {
              animation: card-main 3s linear infinite;
            }
          }

          .title {
            text-align: center;
            color: black;
            font-size: 1.2rem;
            font-weight: 600;
            margin-top: 70px;
          }

          .desc {
            text-align: center;
            color: black;
            width: 500px;
            font-size: 0.8rem;
            margin: auto;
          }

          .controls-grid {
            margin-top: 40px;

            .arrow-grid {
              display: flex;
              align-items: center;
              justify-content: center;

              .arrow {
                color: black;
                cursor: pointer;
                font-size: 15px;
              }
            }

            .pointers {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 3px;

              .dot-point {
                height: 8px;
                width: 8px;
                margin-left: 3px;
                margin-right: 3px;
                background-color: #bbb;
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;

                cursor: pointer;
              }

              .dot-point-selected {
                height: 8px;
                width: 8px;
                margin-left: 3px;
                margin-right: 3px;
                background-color: black;
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;

                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .mobile {
    display: none;
  }
  @media (max-width: 900px) {
    .web {
      display: none;
    }

    .mobile {
      display: block;

      .appbar {
        background-color: black;
        box-shadow: none;
        height: 80px;

        .top {
          margin-top: 10px;
          margin-left: 10px;
          margin-right: 10px;

          .logo {
            height: 2.5rem;
            vertical-align: middle;
          }
        }
      }

      .foot {
        position: absolute;
        bottom: 0;
        background-color: rgba(33, 33, 33, 0.9);
        color: black;
        text-align: center;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 0.85rem;
      }
    }
  }

  @keyframes card {
    0% {
      transform: translateY(0px);
    }
    25% {
      transform: translateY(-15px);
    }
    50% {
      transform: translateY(0px);
    }
    75% {
      transform: translateY(15px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  @keyframes card-main {
    0% {
      transform: translateY(0px);
    }
    25% {
      transform: translateY(15px);
    }
    50% {
      transform: translateY(0px);
    }
    75% {
      transform: translateY(-15px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  @keyframes moveSlideLeft {
    100% {
      transform: translateX(-80%);
      // transform: translateX(-66.6666%);
    }
  }

  @keyframes moveSlideRight {
    100% {
      transform: translateX(80%);
      // transform: translateX(-66.6666%);
    }
  }
}
