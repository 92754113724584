.preview-container {
  .navbar {
    width: 100%;
    right: 0;
    align-items: center;
    background-color: #fff;
    display: flex;
    height: 75px;
    box-shadow: 6px 6px 24px 6px hsl(300deg 6% 90% / 48%);
  }
  .hero-section {
    margin-bottom: 3%;
    .background-image {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: 0;
      width: 100%;
      height: 65vh;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.4;
      }
      .content {
        position: absolute;
        top: 0;
        width: 60%;

        h1,
        p {
          color: #fff;
        }
        hr {
          margin-top: 3%;
        }
        h1 {
          font-size: 3.5rem;
          font-weight: 700;
        }
        .para {
          font-size: 2rem;
        }
        p {
          width: 70%;
          // font-size: 1rem;
        }
      }
    }
  }
  .content-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 10%;
    .content-block {
      display: flex;
      justify-content: center;
      align-items: center;

      .block {
        p {
          margin-top: 1em;
          letter-spacing: -0.003em;
          font-size: 1rem;
          font-size: 18px;
          line-height: 32px;
          padding-right: 20px;
        }
        h2 {
          margin-top: 0;
        }
        img {
          object-fit: contain;
          width: 100%;
        }

        .image-text {
          display: flex;
          justify-content: space-between;
          .image {
            width: 30%;

            img {
              width: 100%;
              aspect-ratio: 1/1;
              height: fit-content;
            }
          }
          .text {
            width: 70%;
            height: fit-content;
            padding: 0 20px;
          }
        }
      }
    }
    .content-block-direction1 {
      flex-direction: row;
      .block {
        width: 55%;
      }
      .img-container {
        width: 45%;
        object-fit: contain;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .content-block-direction2 {
      flex-direction: row-reverse;
      .block {
        width: 55%;
      }
      .img-container {
        width: 45%;
        object-fit: contain;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .content-block-normal {
      .block {
        width: 100%;
      }
      .img-container {
        width: 100%;
        object-fit: contain;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .preview-container {
    .hero-section {
      .background-image {
        .content {
          width: 75%;
        }
      }
    }
    .content-section {
      width: 75%;
      .content-block {

        .block {
        

          .image-text {
            display: flex;
            justify-content: space-between;
            .image {
              width: 30%;

              img {
                width: 100%;
                aspect-ratio: 1/1;
                height: fit-content;
              }
            }
            .text {
              width: 70%;
              height: fit-content;
              padding: 0 20px;
            }
          }
        }
      }
      .content-block-direction1 {
        flex-direction: column;
        .block {
          width: 100%;
        }
        .img-container {
          width: 100%;
          object-fit: contain;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .content-block-direction2 {
        flex-direction: column-reverse;
        .block {
          width: 100%;
        }
        .img-container {
          width: 100%;
          object-fit: contain;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .content-block-normal {
        .block {
          width: 100%;
        }
        .img-container {
          width: 100%;
          object-fit: contain;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .preview-container {
    .hero-section {
      .background-image {
        .content {
          width: 80%;
        }
      }
    }
    .content-section {
      width: 80%;
      .content-block {

        .block {
        

          .image-text {
            display: flex;
            justify-content: space-between;
            .image {
              width: 30%;

              img {
                width: 100%;
                aspect-ratio: 1/1;
                height: fit-content;
              }
            }
            .text {
              width: 70%;
              height: fit-content;
              padding: 0 20px;
            }
          }
        }
      }
      .content-block-direction1 {
        flex-direction: column;
        .block {
          width: 100%;
        }
        .img-container {
          width: 100%;
          object-fit: contain;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .content-block-direction2 {
        flex-direction: column-reverse;
        .block {
          width: 100%;
        }
        .img-container {
          width: 100%;
          object-fit: contain;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .content-block-normal {
        .block {
          width: 100%;
        }
        .img-container {
          width: 100%;
          object-fit: contain;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
