.login-component {
  .title-login {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .title-login-tag {
    color: grey;
    margin-top: -20px;
    font-size: 0.8rem;
    margin-bottom: 50px;
  }

  .login-btn {
    background-color: rgb(166, 0, 113);
    border: 2px solid rgb(166, 0, 113);
    color: white;
    font-family: "Montserrat", sans-serif;
    padding: 8px 15px;
    font-size: 0.7rem;
    float: right;
    margin-top: 35px;

    &:hover {
      background-color: rgb(166, 0, 113);
      transform: translateY(-2px);
    }

    .loading {
      .spin {
        display: inline-block;
        vertical-align: middle;
      }

      .spacer {
        display: inline-block;
        margin-left: 20px;
        vertical-align: middle;
      }
    }
  }
  .forgot-pwd-text {
    margin-top: 30px;
    color: rgb(166, 0, 113);
    text-decoration: underline;
    font-size: 0.75rem;
    font-weight: 700;

    .forgot-pwd-text-child {
      width: 120px;
      cursor: pointer;

      &:hover {
        transform: translateY(-2px);
      }
    }
  }

  .new {
    margin-top: 100px;
    text-align: center;
    font-size: 0.8rem;

    .reg-link {
      color: rgb(166, 0, 113);
      font-weight: 700;
      cursor: pointer;
    }
  }
}
