@import "../../../../styles/variables.scss";

.container {
  // height: 100%;
  display: flex;
  align-items: center;
  align-self: stretch;
  margin-left: 5%;

  button {
    display: flex;
    align-items: center;
    color: $nav-option-clr;
    font-weight: 550;
    font-size: $nav-option-size;
    background: none;
    border: none;
    padding: 0;
    outline: inherit;
    cursor: pointer;
  }
  button:hover {
    // color: blue;
    color: $shade1;
    span {
      color: $shade1;
      //    color: blue;
    }
  }

  .icone_nav {
    transition: all 0.3s ease-in;
    transform: rotate(0deg);
  }
  span {
    width: max-content;
    color: $nav-option-clr;
    font-weight: 550;
    font-size: $nav-option-size;
  }
}
.container_active {
  border-bottom: solid 3px $shade1;
  .icone_nav {
    transform: rotate(90deg);
  }
  span {
    // color:blue
    color: $shade1;
  }
}

@media screen and (max-width: $tab_screen) {
  .container {
    button {
      font-size: $nav-option-size * 0.9;
    }

    span {
      font-size: $nav-option-size * 0.9;
    }
  }
}
@media screen and (max-width: $min_tab_screen) {
  .container {
    padding: 0% 6%;
    margin-left: 0;
    width: 100%;
    .icone_nav {
      transition: none;
    }
    button {
      padding: 4% 0;
      font-size: $nav-option-size * 1.2;
      font-weight: 600;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    button:hover {
      color: $nav-option-clr;
      span {
        color: $nav-option-clr;
      }
    }
    span {
      font-size: $nav-option-size * 1.2;
      font-weight: 600;
    }
    &:hover {
      background-color: #f7f2f7;
    }
  }
}
