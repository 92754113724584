.home {
  height: 100vh;
  width: 100vw;
  margin: 0px;
  overflow-y: hidden;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;

  .drawer {
    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      // position: relative;
      // top: 0;
      // left: 0;
    }
    .logo-drawer {
      height: 70px;
      width: 70px;
      border-radius: 50%;
      padding: 15px;
      cursor: pointer;
      object-fit: contain;
    }

    // .logo-1 {
    //   position: absolute;
    //   top: 0;
    //   z-index: 1;
    // }

    // .logo-2 {
    //   position: absolute;
    //   top: 0;
    //   z-index: 1;
    // }
    .item {
      cursor: pointer;
    }
    .drawer-type {
      margin-top: 10px;
      display: flex;
      align-items: center;
      .line-start {
        height: 1px;
        width: 7px;
        background-color: rgba($color: #000000, $alpha: 0.4);
        display: inline-block;
        vertical-align: middle;
      }

      .text {
        display: inline-block;
        vertical-align: middle;
        padding-left: 10px;
        padding-right: 10px;

        font-size: 0.85rem;
        font-weight: 600;
      }

      .line {
        height: 1px;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.4);
        display: inline-block;
        vertical-align: middle;
      }
    }
    .drawer-foot {
      /* bottom: 0; */
      /* width: 250px; */
      margin-top: auto;
    }
  }

  .main {
    flex-grow: 1;
  }
}
