@import "../../../styles/variables.scss";

.container {
  // border-top: 15px solid rgb(56, 169, 255);
  border-top: 7px solid $shade1;
  background-color: #ffff;
  a {
    color: #000;
  }
  .wrapper {
    padding: 0 48px;
    .navigations {
      .navs {
        height: 92px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .logo {
          img {
            width: 40px;
          }
          margin-right: 32px;
        }

        .nav_options {
          display: flex;
          padding: 24px 0;
          grid-gap: 20px;
          gap: 20px;

          li {
            a {
              font-size: $nav-option-size;
              font-weight: 600;
              &:hover {
                color: #b306b3;
                // text-decoration: underline;
              }
            }
          }
        }
        .socials {
          display: flex;
          grid-gap: 16px;
          gap: 16px;
          margin-left: auto;
          li {
            a {
              color: #919191;
            }
          }
        }
      }
      hr {
        background-color: #e0e1e2;
        height: 2px;
        border: 0;
      }
      .options {
        padding: 48px 0 72px;
        display: grid;
        grid-column-gap: 32px;
        column-gap: 32px;
        grid-template-columns: repeat(3, 1fr);

        li {
          .head {
            font-size: $nav-option-size;
            font-weight: 600;
            line-height: 1.35;
            padding-bottom: 8px;
          }
          ul {
            li {
              display: flex;
              align-items: center;
              height: 50px;
              a {
                &:hover {
                  color: #b306b3;
                  // text-decoration: underline;
                }
              }
            }
          }
        }
      }
      .navs_options_mobile {
        display: none;
      }
      .options_mobile {
        display: none;
      }
    }
  }

  .marketing {
    background-color: #f2f4f7;
    .wrapper {
      padding: 0 48px;
      .features {
        display: flex;
        align-items: center;
        height: 64px;
        ul {
          display: flex;
          align-items: center;
          grid-gap: 16px;
          gap: 16px;
          li {
            a {
              color: #87868686;
            }
          }
        }
      }
    }
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif !important;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }
}

@media screen and (max-width: $min_tab_screen) {
  .container {
    .wrapper {
      .navigations {
        .options {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 26px;
          gap: 26px;
          padding: 28px 0 28px;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile_screen_footer) {
  .container {
    .wrapper {
      .navigations {
        .options {
          display: none;
        }
        .navs {
          .nav_options {
            display: none;
          }
        }
        .navs_options_mobile {
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 24px 0;
          grid-gap: 20px;
          gap: 20px;

          li {
            a {
              font-size: $nav-option-size;
              font-weight: 600;
              &:hover {
                color: #b306b3;
                // text-decoration: underline;
              }
            }
          }
        }

        .options_mobile {
          display: block;
          .head_list {
            border-bottom: 2px solid #e0e1e2;
          }
          li {
            // transition: all .5s ease;

            button {
              background-color: #ffff;
              border: 0;
              width: 100%;
              font-weight: 600;
              font-size: $nav-option-size;
              display: flex;
              justify-content: space-between;
              padding: 16px 0;
              cursor: pointer;
              .dir_icon {
                color: #616161;
                font-weight: 600;
              }
            }
            .options_list {
              display: block;

              overflow: hidden;
              height: 0;
              ul {
                margin-bottom: 16px;
                li {
                  margin-bottom: 8px;
                  &:hover {
                    color: #b306b3;
                    // text-decoration: underline;
                  }
                }
              }
            }
          }
        }

        .options_list_active {
          li {
            .options_list {
              display: block;
              overflow: hidden;
              height: auto;
            }
          }
        }
      }
    }

    .marketing {
      background-color: #f2f4f7;
      .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }
  }
}
