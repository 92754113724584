@import "../../../../styles/variables.scss";

.container {
  height: $nav-height;
  background-color: #ffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // box-shadow: 0 0 20px rgb(15 48 106 / 5%);
  // box-shadow: 0 0 20px rgba(106, 15, 98, 0.05);
  position: relative;

  .part1 {
    display: flex;
    justify-content: space-evenly;
    // width: 50%;
    padding: 0 2%;
    transition: all 1s ease-in-out;
    .logo {
      transition: all 0.5s ease-in-out;
      display: flex;
      align-items: center;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $nav-logo;
        color: #b306b3;
        font-weight: 700;
      }
      img {
        width: 60px;
        position: relative;
        z-index: 2;
        // height: 100%;
      }
      width: 40%;
    }

    .logo_active {
      // width: 8%;
    }

    .options {
      position: static;
      transition: all 0.5s ease-in-out;
      display: flex;
      width: 60%;
      background-color: #ffff;
      width: auto;
      z-index: auto;
    }

    .options_active {
      width: 90%;
    }
  }
  .part2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
    // background-color: rgb(53, 8, 255);

    margin: auto 2% auto 132px;
    a {
      color: $nav-option-clr;
      font-weight: 550;
      font-size: $nav-option-size;
      margin: 0 2%;
      cursor: pointer;
      &:hover {
        color: #b306b3;
      }
    }
    position: relative;
    z-index: 0;

    .burger_menu {
      visibility: hidden;
      opacity: 0;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      align-self: stretch;
      width: $burger_width;
      height: $burger_height;
      padding: 0;
      margin: 0;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      border: 0;
      background-color: #ffff;
      .line {
        background-color: #000;
        width: $burger_width;
        height: 3px;
        border-radius: 5px;
        width: $burger_width;
        box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
        transition: all 0.5s ease-in-out;
      }
      .l1 {
        transform: rotate(0) translate(0, 0);
      }
      .l2 {
        opacity: 0;
        visibility: hidden;
      }
      .l3 {
        transform: rotate(0) translate(0, 0);
      }
    }
  }
}

@media screen and (max-width: $min_desktop_screen) {
  .container {
    .part2 {
    }
  }
}

@media screen and (max-width: $tab_screen) {
  .container {
    .part1 {
      .logo {
        transition: all 0.5s ease-in-out;
        display: flex;
        align-items: center;
        span {
          font-size: $nav-logo * 0.8;
          color: #b306b3;
          font-weight: 700;
        }
      }
    }

    .part2 {
      a {
        color: $nav-option-clr;
        font-weight: 550;
        font-size: $nav-option-size * 0.9;
        margin: 0 2%;
      }
    }
  }
}

@media screen and (max-width: $min_tab_screen) {
  .container {
    .part1 {
      width: 30%;
      justify-content: flex-start;
      .options {
        transition: none;
        visibility: hidden;
        position: fixed;
        // background-color: #f7fbff;
        background-color: #fff7fe;
        z-index: -1;
        left: 0;
        padding-top: $nav-height;
        display: initial;
        flex-direction: column;
        height: 100%;
        width: 50%;
      }
      .logo_active {
        // width: 40%;
      }
      .option_open {
        visibility: visible;
      }
      .options_active {
        width: 50%;
        visibility: hidden;
      }
    }

    .part2 {
      width: 70%;

      .link1 {
        margin: auto 40px;
      }
      .link2 {
        margin: auto 40px;
      }

      .burger_menu {
        visibility: visible;
        opacity: 1;
        margin-left: 10px;
        .l2 {
          opacity: 1;
          visibility: visible;
        }
      }
      .burger_menu_action {
        visibility: visible;
        opacity: 1;
        margin-left: 10px;
        .l1 {
          transform: rotate(45deg)
            translate($burger_width * 0.25, $burger_width * 0.25);
        }
        .l2 {
          opacity: 0;
          visibility: hidden;
        }
        .l3 {
          transform: rotate(-45deg)
            translate($burger_width * 0.25, -$burger_width * 0.25);
        }
      }
      a {
        color: $nav-option-clr;
        font-weight: 550;
        font-size: $nav-option-size;
        margin: 0 2%;
      }
    }
  }
}

@media screen and (max-width: $mobile_screen) {
  .container {
    padding: 0 3%;
    .part1 {
      width: 50%;
      .options {
        width: 100%;
      }
    }
    .part2 {
      width: 50%;
      margin: auto 2% auto 0;
      .link1 {
        display: none;
      }
    }
  }
}
