.item {
  padding-top: 15px;
  padding-bottom: 15px;

  .brick {
    display: inline-block;
    width: 3px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 3px;
    vertical-align: middle;
    border-radius: 100px;
  }

  .img {
    padding-left: 40px;
    padding-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }

  .spacer {
    padding-left: 5px;
    display: inline-block;
    vertical-align: middle;
  }
}

.selected {
  padding-top: 15px;
  padding-bottom: 15px;

  .brick {
    display: inline-block;
    width: 3px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 3px;
    background-color: rgb(166, 0, 113);
    vertical-align: middle;
    border-radius: 100px;
  }

  .img {
    padding-left: 40px;
    padding-right: 10px;
    display: inline-block;
    vertical-align: middle;
    color: rgb(166, 0, 113);
  }

  .spacer {
    padding-left: 5px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    font-size: 0.95rem;
    color: rgb(166, 0, 113);
  }
}
