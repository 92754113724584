.input-form {
  display: flex;
  align-items: center;
  // justify-content: center;
  margin-top: 25px;

  .label {
    display: inline-block;
    margin-right: 50px;
    font-size: 0.8rem;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    width: 20%;
  }
  input {
    width: 80%;
  }

  .field {
    display: inline-block;
    border: none;
    margin-left: auto;
    font-family: "Montserrat", sans-serif;
    width: 230px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    border: 1.5px solid rgb(223, 223, 223);

    &:focus {
      outline: none;
      border: 1.5px solid #a60071;
    }
  }

  .field-with-err {
    display: inline-block;
    border: none;
    margin-left: auto;
    font-family: "Montserrat", sans-serif;
    width: 230px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    border: 1.5px solid red;

    &:focus {
      outline: none;
      border: 1.5px solid red;
    }
  }
}

.err-space {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  .err-text-field {
    text-align: end;
    font-size: 0.7rem;
    color: red;
    font-weight: 500;
    margin-top: -5px;
    width: 300px;
  }
}
