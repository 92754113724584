.container-overview {
  position: relative;
  height: 100%;

  .input-container-search {
    margin-left: auto;
    margin-right: 30px;

    width: 300px;
    border-radius: 100px;
    background-color: white;
    display: flex;
    align-items: center;
    box-shadow: 0 0 10px 1px rgb(241, 241, 241);

    .icon {
      height: 20px;
      color: #8c8c8c;
      min-width: 50px;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
    }

    .input-field {
      font-family: 'Montserrat', sans-serif;
      width: 100%;
      padding: 10px;
      height: 20px;
      border: none;
      border-bottom-right-radius: 100px;
      border-top-right-radius: 100px;
      padding-left: 0;
      &:active {
        border: none;
        outline: none;
      }

      &:focus {
        border: none;
        outline: none;
      }
    }
  }

  .toggle-div-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 70px;
    .toggle-div {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      font-weight: 600;
      cursor: pointer;
    }

    .active-toggle {
      border-bottom: 3px solid #a60071;
    }
  }

  .inner-container {
    padding: 2% 5%;
    .heading {
      padding-top: 5%;
      padding-bottom: 0%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .head-title {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 80px;
        }
        h1 {
          margin: 0 20px;
        }
      }
      p {
        font-size: 1.2rem;
        text-align: center;
      }
    }
    .body {
      h2 {
        text-align: center;
        font-size: 1.4rem;
        font-weight: 500;
      }
      .quick-access {
        display: grid;

        grid-gap: 15px;
        .quick-grid {
          border: 1px solid #dadce0;
          border-radius: 8px;
          background-color: #fff;
          display: inline-flex;
          flex-shrink: 0;
          height: 86px;
          cursor: pointer;
          .content-box {
            display: flex;
            align-items: center;
            padding-left: 20px;
            p {
              margin: 0 10px;
            }
          }
        }
      }
    }
  }

  .footer {
    display: none;
    flex-direction: row;
    flex: 1 1 auto;
    height: 80px;
    align-items: baseline;
    position: absolute;
    bottom: 0;
    width: 100%;
    .content {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      flex: 1 1 auto;
      height: 80px;
      display: flex;
      box-sizing: border-box;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
      padding: 0 24px;

      transition: border-top 0.2s;
    }
  }
}

@media screen and (max-width: 1200px) {
  .container-overview {
    .inner-container {
      .body {
        .quick-access {
          display: grid;
          grid-template-columns: 30% 30% 30%;
          grid-gap: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .container-overview {
    .inner-container {
      .body {
        .quick-access {
          display: grid;
          grid-template-columns: 45% 45%;
          grid-gap: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .container-overview {
    .inner-container {
      .heading {
        padding-top: 20%;
        padding-bottom: 2%;
        .head-title {
          display: flex;
          align-items: center;
          img {
            width: 50px;
          }
          h1 {
            margin: 0 20px;
            font-size: 1.5rem;
          }
        }
        p {
          font-size: 1.1rem;
        }
      }
      .body {
        h2 {
          font-size: 1.2rem;
          font-weight: 500;
        }
        .quick-access {
          display: grid;
          grid-template-columns: 80%;
          grid-gap: 30px;
        }
      }
    }
  }
}
