.show-pub-container {
  .secondary-div {
    .input-container {
      .icon {
        height: 20px;
        color: #8c8c8c;
        min-width: 50px;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
      }

      .input-field {
        font-family: 'Montserrat', sans-serif;
        width: 100%;
        padding: 10px;
        height: 20px;
        border: none;
        border-bottom-right-radius: 100px;
        border-top-right-radius: 100px;
        padding-left: 0;
        &:active {
          border: none !important;
          outline: none !important;
        }

        &:focus {
          border: none;
          outline: none;
        }
      }
    }

    .search-field {
      margin-left: auto;
      margin-right: 30px;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 300px;
      border-radius: 100px;
    }
  }
}
