.preloader {
  height: 100%;
  //   height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background: #fff;
  position: fixed;
  // top: 0;
  left: 0;
  bottom: 0;
  z-index: 1200+1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  overflow: hidden !important;

  // .texts-container {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   height: 60px;
  //   width: 100%;
  //   font-size: 2rem;
  //   overflow: hidden;
  //   font-weight: 800;
  //   opacity: 0;
  //   color: #fff;
  // }
}

.puff-loader-preloader {
  span {
    border: 8px solid rgb(166, 0, 113) !important;
  }
}
