@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');


$Poppins: 'Poppins', sans-serif;

//shades:
$shade1:#b51783;
$shade1_1:#b51783;
$shade1_2:#af3087;
$shade1_3:#b34a91;
$shade2:#f8def0;
$shade3:#dc83c0;

//navbar 
$nav-height:75px;
$nav-option-clr:#333333;
$nav-option-size:1rem;
$nav-background-clr:#ffff;
$nav-logo:2rem;

//
$text-color:#fff;
//burder
$burger_width:30px;
$burger_height:24px;

// scale 
$min_desktop_screen:1300px;
$tab_screen:1200px;
$min_tab_screen:1000px;
$mobile_screen:800px;
$mobile_screen_footer:850px;


