@import "../../styles/variables.scss";

.container {
  // padding: 2rem;
  line-height: 1.25;
  position: relative;
  .bg_color1 {
    // background-color: #ffeab6;

    background-color: $shade2;
  }
  .bg_color2 {
    // background-color: #d0f0fd;

    background-color: $shade3;
  }
  .bg_color3 {
    background-color: #333333;
    color: #fff;
  }
  .wrapper {
    max-width: 72rem;
    margin-left: auto;
    margin-right: auto;
    // margin-top: 2rem;
    // padding-top: 4rem;
    .heading {
      text-align: center;
      padding-bottom: 4rem;
      h1 {
        font-size: 2.8rem;
        font-weight: 600;
      }
    }
    .pricings {
      // padding-bottom: 4rem;

      .flexbox {
        display: flex;
        justify-content: center;
        letter-spacing: 0.01em;
      }

      .flexbox {
        .inner_flex1 {
          display: flex;
          position: relative;
          padding-left: 1rem;
          // padding-right: .5rem;

          width: 50%;
          .popular {
            font-size: 1rem;
            border-top-left-radius: 14px;
            border-top-right-radius: 14px;
            padding: 1rem;
            // background-color: #2d7ff9;
            background-color: $shade1;
            width: 100%;
            color: #ffff;
          }
          h3 {
            margin: 0;
            font-weight: 600;
            font-size: 1.25rem;
            width: 100%;
            padding-top: 2rem;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 0.5rem;
          }
          .round {
            border-top-right-radius: 14px;
            border-top-left-radius: 14px;
          }
          .border_h {
            border-top: 1px solid rgb(204, 204, 204);
            border-left: 1px solid rgb(204, 204, 204);
            border-right: 1px solid rgb(204, 204, 204);
          }

          .content_head {
            font-weight: 500;
            font-size: 1.2rem;
            width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 0.5rem;
          }
          .content_head_border {
            border-left: 1px solid rgb(204, 204, 204);
            border-right: 1px solid rgb(204, 204, 204);
          }
          .price {
            // padding-left: 1rem;
            // padding-right: 1rem;
            padding-bottom: 0.5rem;
            width: 100%;
          }
          .price_border {
            border-left: 1px solid rgb(204, 204, 204);
            border-right: 1px solid rgb(204, 204, 204);
          }
          .price_title {
            font-size: 3.2rem;
            font-weight: 500;
            margin-right: 1rem;
          }
          .billing {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 1rem;
            width: 100%;
            .bill {
              font-size: 1rem;
              span {
                font-weight: 600;
              }
            }
          }
          .billing_border {
            border-left: 1px solid rgb(204, 204, 204);
            border-right: 1px solid rgb(204, 204, 204);
          }
          .button {
            width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 1rem;
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            a {
              padding: 1rem;
              width: 100%;
              border: 1px solid #b51783;
              color: #b51783;
              text-align: center;
              border-radius: 9999px;
              background-color: #f8def0;
              font-weight: 600;
              text-decoration: none;
            }
            // .btn_typ2{
            //     // background-color: #2d7ff9;
            //     background-color: $shade1;
            //     color: #fff;
            //     border: 0;
            // }

            .btn_clicked {
              // background-color: #2d7ff9;
              border: 1px solid #333333;
              color: #fff;
              border: 0;
              background-color: $shade1;
            }

            // .btn_typ2{
            //     background-color: #2d7ff9;
            // }

            // .btn_typ3{
            //     background-color: #2d7ff9;
            // }
          }

          .button_border {
            border-left: 1px solid rgb(204, 204, 204);
            border-right: 1px solid rgb(204, 204, 204);
          }

          .list_container_border {
            border-bottom: 1px solid rgb(204, 204, 204);
            border-left: 1px solid rgb(204, 204, 204);
            border-right: 1px solid rgb(204, 204, 204);
          }

          .list_container {
            width: 100%;
            border-bottom-right-radius: 14px;
            border-bottom-left-radius: 14px;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
            padding-top: 15%;
            display: flex;
            flex-direction: column;
            .head {
              padding-bottom: 0.75rem;
              font-size: 1rem;
              line-height: 1.25;
            }
            .list {
              font-size: 1rem;
              padding-bottom: 0.75rem;
              .item {
                padding-bottom: 0.25rem;
                display: flex;
                align-items: center;
                .icon {
                  align-items: flex-start;
                  padding-right: 0.5rem;
                }
                span {
                  align-self: flex-start; //?
                }
              }
            }
          }
        }
      }
    }

    .mobile_pricing {
      display: none;
      flex-direction: column;
      .flexbox {
        display: flex;
        flex-direction: column;
        // width: 100%;
        margin-top: 15px;
        border-radius: 9999px;
        padding: 40px;

        .top_content {
          h3 {
            margin-bottom: 0.25rem;
            font-size: 1.25rem;
          }
          p {
            font-size: 1rem;
            margin-bottom: 1rem;
            font-weight: 500;
          }
        }

        .mid_content {
          h2 {
            font-size: 2.8rem;
            margin-bottom: 3rem;
          }
          a {
            // min-width: 180px;
            padding: 0.8rem 1rem;
            width: 100%;
            text-align: center;
            border-radius: 9999px;
            font-weight: 600;
          }
        }

        .bottom_content {
          margin-top: 2.2rem;
          font-size: 1rem;
          font-weight: 500;
          .head {
          }
          .list_container {
            margin-top: 1rem;

            .item {
              padding-bottom: 0.25rem;
            }
          }
        }
      }
      .flexbox_type1 {
        border: 1px solid rgb(204, 204, 204);
        border-radius: 14px;
        a {
          border: 1px solid #333333;
          background-color: #fff;
          color: #333333;
        }
      }

      /*
            .bg_color1{
                background-color: #ffeab6;
            }
            .bg_color2{
                background-color: #d0f0fd;
            }
            .bg_color3{
                background-color: #333333;
                color: #fff;
            }
            */
      .flexbox_type2 {
        // background-color: #ffeab6;
        background-color: $shade2;
        border-radius: 14px;
        a {
          border: 1px solid #333333;
          background-color: #fff;
          color: #333333;
        }
      }

      .flexbox_type3 {
        // background-color: #d0f0fd;
        background-color: $shade3;
        border-radius: 14px;
        .btn_typ2 {
          // background-color: #2d7ff9;
          background-color: $shade1;
          color: $shade2;
          border: 0;
        }
      }

      .flexbox_type4 {
        background-color: #333333;
        border-radius: 14px;
        color: #fff;
        a {
          border: 1px solid #333333;
          background-color: #fff;
          color: #333333;
        }
      }
    }
  }
}

@media screen and (max-width: $min_tab_screen) {
  .container {
    display: flex;
    flex-direction: column;
    .wrapper {
      max-width: 100rem;
      margin: 0;

      .heading {
        text-align: center;
        padding-bottom: 4rem;
        h1 {
          font-size: 2.5rem;
          font-weight: 600;
        }
      }
      .pricings {
        display: none;
        .flexbox {
          .inner_flex1 {
            width: 100%;
          }
        }
      }
      .mobile_pricing {
        display: flex !important;
      }
    }
  }
}
